// @ts-check
import React, { useEffect, useRef, useState } from 'react';
import Layout from '../../components/layout';
import ReturnLink from '../../components/InfosPages/ReturnLink';
import StyledButton from '../../components/InfosPages/StyledButton';
import InfoTitle from '../../components/InfosPages/infosTitle';
import styled from '@emotion/styled';
import { nanoid } from 'nanoid';
import { throttle } from 'lodash';

// ASSETS

// Colors
const green = 'rgba(47, 137, 117, 1)';
const gray = '#FCFCFC';
const grayFont = 'rgba(122, 122, 122, 1)';

const LeftColumnContainer = styled.div`
  @media (min-width: 767px) {
    height: calc(100vh - 102px);
  }
  height: fit-content !important;
`;

const NavItem = styled.nav`
  color: ${grayFont};
  &.selected {
    color: ${green};
    font-weight: bold;
  }
`;

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function scrollInResponsive(titleElement) {
  console.log(titleElement);
}

export default function CGU() {
  const [currentNav, setCurrentNav] = useState(null);
  const oldNavId = usePrevious(currentNav);

  const [formatedInfos, setFormatedInfos] = useState(null);

  function scrollToTitle(id) {
    if (typeof window !== 'undefined') {
      // console.log('ok', id, document.getElementById(id), document.getElementById(`NavItem-${id}`));
      //TODO
      const titleItem = document.getElementById(id);
      // console.log(titleItem);
      if (titleItem) {
        const ContentContainer = document.getElementById('ContentContainer');
        window.scrollTo({
          top: titleItem.offsetTop + 150,
          behavior: 'smooth',
        });
      }
    }
  }

  const handleScroll = throttle(() => {
    const titleElements = Array.from(document.querySelectorAll('.Item'));
    const scrollTop = window.scrollY;
    const windowHeight = window.innerHeight;
    const currentTitle = titleElements.reduce((acc, value, index) => {
      if (!acc) {
        const valueTop = value.getBoundingClientRect().top;
        if (valueTop > 102 && valueTop < windowHeight) {
          acc = value;
        }
      } else {
        const accTop = acc.getBoundingClientRect().top;
        const valueTop = value.getBoundingClientRect().top;
        if (valueTop > 102 && valueTop < windowHeight && valueTop < accTop) {
          acc = value;
        }
      }
      return acc;
    }, null);

    if (currentTitle) {
      const idNav = currentTitle.getAttribute('id');
      setCurrentNav(idNav);
    }
  }, 10);

  useEffect(() => {
    // console.log(currentNav);
    if (oldNavId !== currentNav && currentNav) {
      const navItem = document.getElementById(`NavItem-${currentNav}`);
      if (navItem) navItem.classList.add('selected');
      if (oldNavId) {
        const oldItem = document.getElementById(`NavItem-${oldNavId}`);
        if (oldItem) {
          oldItem.classList.remove('selected');
        }
      }
    }
  }, [currentNav]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    handleScroll();
  }, [handleScroll]);

  useEffect(() => {
    setFormatedInfos(
      Object.fromEntries(
        Object.entries(require('../../CGU.json')).map(([key, value]) => {
          return [key, value.map((m) => ({ ...m, id: nanoid(6) }))];
        })
      )
    );
  }, []);

  return (
    <Layout>
      <div className="">
        <ReturnLink />
        {formatedInfos && (
          <div className="MainWrapper / -lg:px-16 pb-36 flex flex-col items-start justify-start max-w-full px-3 mt-6">
            <h1 className="my-5 text-2xl font-medium text-green-700">Conditions générales d'utilisation</h1>
            <hr className="background-green-700 w-full h-2" />
            <div className="md:flex-col -lg:mb-24 relative flex flex-row items-start w-full">
              {/* COLONNNE DE GAUCHE */}
              <LeftColumnContainer className="TopTimeline / -md:w-1/3 -md:flex-col md:justify-between md:bg-white -md:overflow-y-auto -md:top-28 flex-nowrap -md:pt-6 -md:pb-6 sticky flex flex-row w-full pt-5 pr-2">
                <div className="md:hidden flex flex-col">
                  {formatedInfos.firstPart?.map((info, index) => (
                    <NavItem
                      className={`Title / my-2`}
                      id={`NavItem-${info.id}`}
                      key={info.id}
                      onClick={() => {
                        scrollToTitle(info.id);
                      }}
                    >
                      <span className="text-lg">
                        {index + 1} - {info.title}
                      </span>
                    </NavItem>
                  ))}
                  <h2 className="-lg:text-2xl pr-1 my-5 text-lg font-medium text-green-700">
                    Procès-verbal de réception de chantier, mention d’information et convention de preuve
                  </h2>
                  <hr className="background-green-700 w-11/12 h-2" />
                  {formatedInfos.secondPart.map((info, index) => (
                    <NavItem
                      className="Title / my-2"
                      id={`NavItem-${info.id}`}
                      key={info.id}
                      onClick={() => scrollToTitle(info.id)}
                    >
                      <span className="text-lg">
                        {index + 1} - {info.title}
                      </span>
                    </NavItem>
                  ))}
                </div>
                <div className="Timeline / -md:hidden flex-nowrap flex flex-row items-center justify-between overflow-x-auto">
                  {formatedInfos.firstPart.map((info, index) => (
                    <StyledButton
                      className="text-sm"
                      text={info.title}
                      id={`NavItem-${info.id}`}
                      key={index}
                      idToScroll={info.id}
                    />
                  ))}
                  {formatedInfos.secondPart.map((info, index) => (
                    <StyledButton
                      className="text-sm"
                      text={info.title}
                      id={`NavItem-${info.id}`}
                      key={index}
                      onClick={() => scrollToTitle(info.id)}
                      idToScroll={info.id}
                    />
                  ))}
                </div>
              </LeftColumnContainer>
              {/* COLONNE DE DROITE */}
              <div
                className="mb-72 md:w-full flex flex-col w-2/3 px-3 pt-6 pb-20"
                style={{ background: gray }}
                id="ContentContainer"
              >
                {formatedInfos.firstPart.map(({ title, subtitle, text, id }, index) => (
                  <InfoTitle key={id} title={subtitle ?? title} text={text} id={id} CGU={true} />
                ))}

                <h3 className="mb-5 text-2xl font-extrabold" style={{ color: green }}>
                  Procès-verbal de réception de chantier, mention d’information et convention de preuve
                </h3>
                {formatedInfos.secondPart.map(({ title, subtitle, text, id }, index) => (
                  <InfoTitle key={id} title={subtitle ?? title} text={text} id={id} CGU={true} />
                ))}
              </div>
            </div>
          </div>
        )}{' '}
      </div>
    </Layout>
  );
}
