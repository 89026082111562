import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button`
  border-radius: 50px;
  color: rgba(47, 137, 117, 1);
  background: white;
  padding: 8px;
  outline: 0;
  margin-right: 0.5rem;
  border: 2px solid rgba(47, 137, 117, 1);
  margin: 0px 3px;
  white-space: nowrap;

  &:hover {
    background: rgba(47, 137, 117, 1);
    color: white;
  }
  @media (max-width: 767px) {
    padding: 8px;
    font-size: 1rem;
    margin-right: unset;
  }
  @media (max-width: 450px) {
    font-size: 0.75rem;
  }
`;

function scrollToTitle(id) {
  // console.log('ok', id);
  if (id) {
    const titleItem = document.getElementById(id);
    // console.log(titleItem);
    if (titleItem) {
      const ContentContainer = document.getElementById('ContentContainer');
      // console.log(titleItem);
      window.scrollTo({
        top: titleItem.offsetTop + 60,
        behavior: 'smooth',
      });
    }
  }
}

const InfoButton = (props) => {
  return (
    <StyledButton id={props.id ? props.id : ''} onClick={() => scrollToTitle(props.idToScroll)}>
      {props.text}
    </StyledButton>
  );
};

export default InfoButton;
