import React from 'react';
import { Link } from 'gatsby';
import ArrowText from '../../images/ArrowText.svg';

const ReturnLink = () => (
  <div className="-lg:pl-16 w-min flex flex-row justify-start px-3 mt-40">
    <Link to="/" className="flex flex-row">
      <img src={ArrowText} alt="arrowvector" />
      <p className="ml-4 font-bold text-green-700">Retour</p>
    </Link>
  </div>
);

export default ReturnLink;
