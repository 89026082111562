import React from 'react';

const green = 'rgba(47, 137, 117, 1)';

const InfosTitle = ({ CGU, id, title, text }) => {
  return (
    <div className="flex flex-col items-start justify-start max-w-full my-5">
      <h3 id={id} className={`${CGU ? 'Item' : ''} mb-5 text-2xl `} style={{ color: green }}>
        {title}
      </h3>
      <p className="max-w-full text-base" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
};

export default InfosTitle;
